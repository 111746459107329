import React from "react";

export class SectionHeader extends React.Component <{ title: string }> {

    public render() {
        const headerCSS = {
            marginBottom: '2em'
        }

        return (
            <h2 className="ui horizontal divider header"
                style={headerCSS}>
                {this.props.title}
            </h2>
        )
    }
}

export class SectionHeaderWithSpace extends React.Component <{ title: string }> {

    public render() {
        const headerCSS = {
            marginTop: '4em',
            marginBottom: '2em'
        }

        return (
            <h2 className="ui horizontal divider header"
                style={headerCSS}>
                {this.props.title}
            </h2>
        )
    }
}