import * as React from 'react';
import {RefObject} from 'react';
import backgroundImage from '../../asset/image/main/main1.jpg';
import {primary} from "../atomic/Colors";
import './Carousel.css';
import ToRecruitButton from "./ToRecruit";

export default class Carousel extends React.Component<{}> {
    private mainWordRef: RefObject<HTMLDivElement> | null;
    private subWordRefs: Map<number, HTMLDivElement | null>;
    private removeSubWordTime: number;
    private showMainWordDelayTime: number;

    constructor(props: {}) {
        super(props);
        this.mainWordRef = React.createRef();
        this.subWordRefs = new Map<number, HTMLDivElement | null>();
        this.removeSubWordTime = 2400;
        this.showMainWordDelayTime = 2600;
    }

    public componentDidMount(): void {
        this.textAnimationHandle()
    }

    public render() {
        return (
            <div style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                width: '100%',
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center center',
                backgroundAttachment: "fixed",
                color: 'wheat',
            }}>
                <div style={{
                    width: '100%', height: '100%', backgroundColor: 'rgba(0,35,102, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center', position: 'relative',

                }}>
                    <div style={{position:'absolute', top:'35%', height: '300px', marginBottom: '150px'}}>
                        <div style={{
                            fontSize: '60px',
                            color: primary.white,
                            height: '1em',
                            lineHeight: 1,
                            textAlign:'center'
                        }}>
                            {['Invest','In','Your', 'Future'].map((key, index) => (
                                <div key={index} ref={ref => this.subWordRefs.set(index, ref)} className={'initialSubText'} style={{height:'100%'}}>
                                    {key}
                                </div>
                            ))}
                        </div>
                        <div ref={this.mainWordRef} className={'mainText removed'}
                             style={{
                                 marginTop:'-40px',
                                 fontSize: '140px',
                                 color: primary.white,
                                 height: '1em',
                                 lineHeight: 1,
                             }}>
                            Y I G
                        </div>
                    </div>

                    <div style={{position:'absolute', bottom:'20%'}}><ToRecruitButton/></div>
                </div>
            </div>)
            ;
    }

    private textAnimationHandle = () => {
        this.subTextWiden();
        setTimeout(() => {
            this.subTextRemove();
        }, this.removeSubWordTime);

        setTimeout(() => {
            this.showMainText();
        }, this.showMainWordDelayTime)
    };

    private subTextWiden = () => {
        Array.from(this.subWordRefs).forEach(([key, el]) => {
            const subTextElement = el;
            if(subTextElement != null) {
                subTextElement.classList.remove('initialSubText');
                subTextElement.classList.add('subText' + key)
            }
        } )
    };

    private subTextRemove = () => {
        Array.from(this.subWordRefs).forEach(([key, el]) => {
            const subTextElement = el;
            if(subTextElement != null) {
                subTextElement.classList.add('removed')
            }
        } )
    };

    private showMainText = () => {
        if (this.mainWordRef != null && this.mainWordRef.current != null) {
            const mainTextElement = this.mainWordRef.current;
            mainTextElement.classList.remove('removed')
        }
    };
}
