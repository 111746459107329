import * as React from 'react';
import Process from "./Process";
import FAQ from "./FAQ";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import '../../Common.css';
import {Banner, InnerBanner} from "../common/Banner";
import banner from '../../asset/image/main/main1.jpg';
import {Button} from "semantic-ui-react";
import Banner3 from "../../asset/image/recruiting/Banner3.jpg";

interface IProps extends RouteComponentProps<{}> {

}

export default class Recruiting extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props)
    }

    public render() {
        return (
            <div className={'PageWrapper'}>
                {this.renderBanner()}
                {this.renderRouteButtons()}
                <div className={"PageInnerWrapper"}>
                    {this.renderSmallBanner()}
                    <Switch>
                        <Route exact={true} path={'/recruiting'} component={Process}/>
                        <Route path={'/recruiting/FAQ'} component={FAQ} />
                    </Switch>
                </div>
            </div>
        )
    }

    private renderRouteButtons = () => {
        const buttonCSS: React.CSSProperties = {
            padding: "0 2em 0 2em",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            maxWidth: "600px"
        }
        return (
            <div style={buttonCSS}>
                <Button.Group widths='2'>
                    <Button onClick={() => {this.props.history.push('/recruiting')}}>Process</Button>
                    <Button onClick={() => { this.props.history.push('/recruiting/FAQ') }}>FAQ</Button>
                </Button.Group>
            </div>
        )
    };

    private renderBanner = () => {
        return <Banner text={'Recruiting'} imageSrc={banner} />
    };

    private renderSmallBanner = () => (<InnerBanner imageSrc={Banner3}  text={""}/>)
}


