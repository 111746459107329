import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import AuthContextProvider, {AuthContext} from "./context/AuthContext";
import Template from "./Template";
import api from './api';
class App extends React.Component{
    constructor(props: {}) {
        super(props);
    }

    public render(){
        return (
           <AuthContextProvider>
               <Template />
           </AuthContextProvider>
        );
    }
}

export default App;
