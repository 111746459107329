import * as React from 'react';
import {Grid, Image} from "semantic-ui-react";
import SummaryImage from "../../asset/image/members/Summary.png"

interface IState {
    alumniCompanyList: string [];
}

export default class Summary extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            alumniCompanyList: []
        }
    }

    private setSummaries = () => {
        const alumniCompanyList = [
            'Morgan Stanley',
            'BofA Merill Lynch',
            'UBS',
            'Credit Suisse',
            'HSBC',
            'Deutsche Bank',
            'CGS-CIMB',
            'McKinsey',
            'BCG',
            'Bain & Company',
            'Oliver & Wyman',
            '삼성자산운용',
            '한국투자밸류자산운용',
            'BNP파리바자산운용',
            '미래에셋자산운용',
            '교보악사자산운용',
            'DB자산운용',
            'KB자산운용',
            '피데스 자산운용',
            '도이치 자산운용',
            '씨스퀘어 자산운용',
            '유진자산운용',
            '한국투자프라이빗에쿼티',
            'SG프라이빗에쿼티',
            'SBI인베스트먼트',
            '삼성증권',
            'NH투자증권',
            'KB증권',
            '한국투자증권',
            '삼일회계법인',
            '한영회계법인',
            '안진회계법인',
            '국민연금',
            '군인공제회',
            '기술보증기금',
            '신한은행',
            '한국기업평가',
            '한국신용정보',
            '코리아크레딧뷰로',
            'Thomson Reuters',
            '삼성생명',
            '한화생명',
            '삼성전자',
            '현대자동차',
            '현대제철',
            'SK하이닉스',
            'SK경제연구소',
            '서울대 로스쿨',
            '연세대 로스쿨',
            '고려대 로스쿨',
            '국내외 대학원',
            '김&장',
            '법무법인 태평양',
            '법무법인 세종 등'
        ]

        this.setState({alumniCompanyList})
    }

    public componentDidMount() {
        this.setSummaries()
    }

    private renderAlumniCompany = (company: string) => {
        return (
            <Grid.Column>
                <p>{ company }</p>
            </Grid.Column>
        )
    }

    public render() {
        return (
            <>
                <div style={{marginTop: "40px", marginBottom: "40px"}}>
                    <Image src={ SummaryImage }></Image>
                </div>
                <Grid stackable columns={6} relaxed="very">
                    {this.state.alumniCompanyList.map(company => this.renderAlumniCompany(company))}
                </Grid>
            </>
        )
    }
}