import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {Menu, MenuItemProps} from "semantic-ui-react";
import './Gnb.css';
import Login from "./Login";

interface IGnb {
    name: string,
    className: string,
    active: () => boolean,
    onClick: () => void
}

interface IState {
    gnbs: IGnb []
}

class Gnb extends React.Component<RouteComponentProps<{}>, IState> {
    private gnbRefs: Map<string, HTMLDivElement | null>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            gnbs: [
                {
                    name: 'Home',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/'),
                    onClick: () => {
                        this.props.history.push('/')
                    }
                },
                {
                    name: 'YIG',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/yig'),
                    onClick: () => {
                        this.props.history.push('/yig')
                    }
                },
                {
                    name: 'Members',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/members'),
                    onClick: () => {
                        this.props.history.push('/members')
                    }
                },
                {
                    name: 'Recruiting',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/recruiting'),
                    onClick: () => {
                        this.props.history.push('/recruiting')
                    }
                },
                {
                    name: 'Research',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/research'),
                    onClick: () => {
                        this.props.history.push('/research')
                    }
                },
            ]
        };

        this.gnbRefs = new Map<string, HTMLDivElement | null>()
    }
    
    public componentDidMount(): void {
        this.toggleGnbTextColor();
        window.addEventListener('scroll',this.toggleGnbTextColor)
    }

    public componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<IState>, snapshot?: any): void {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.toggleGnbTextColor()
        }
    }

    public render() {
        return (
            <div className={"GnBWrapper"}>
                <Menu text>
                    {this.state.gnbs.map((gnb) => (
                        <div
                        >
                            <Menu.Item
                                key={gnb.name}
                                name={gnb.name}
                                as={'h3'}
                                className={gnb.className}
                                active={gnb.active()}
                                onClick={gnb.onClick}
                            >
                                <div ref={ref => this.gnbRefs.set(gnb.name, ref)}>{gnb.name}</div>
                            </Menu.Item>
                        </div>
                    ))}
                    {/*<Menu.Menu position='right'>*/}
                    {/*    <Login/>*/}
                    {/*</Menu.Menu>*/}
                </Menu>
            </div>
        )
    }

    private toggleGnbTextColor = () => {
        const isWindowScrollIsTop = () => {
            return window.scrollY === 0
        };

        const headerChangeEvent = () => {
            if (!isWindowScrollIsTop()) {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.remove('whiteText')
                    }
                })
            }

            if (isWindowScrollIsTop() && this.props.location.pathname === '/') {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.add('whiteText')
                    }
                })
            }

            if (isWindowScrollIsTop() && this.props.location.pathname !== '/') {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.remove('whiteText')
                    }
                })
            }
        };

        const addClassName = (element: HTMLElement, className: string) => {
            element.classList.add(className)
        };

        const removeClassName = (element: HTMLElement, className: string) => {
            element.classList.remove(className)
        };
        
        headerChangeEvent()
    };
}


export default withRouter(Gnb)

