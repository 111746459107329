
export const primary = {
    blue: '#002366',
    red : '#DDx2C00',
    grey: '#535354',
    white: '#FFFFFF'
};


export default {
    primary
}