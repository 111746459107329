import * as React from 'react';
import RegularSession1 from '../../asset/image/yig/RegularSession1.png';
import RegularSession2 from '../../asset/image/yig/RegularSession2.png';
import EducationSession from '../../asset/image/yig/EducationSession.png';
import Curriculum4 from '../../asset/image/yig/ResearchChallenge.png';
import {SectionHeader, SectionHeaderWithSpace} from "../common/SectionHeader";

interface IState {
    regularSession?: string;
    educationSession?: string;
    researchChallenge?: string;
    meetingWithAlumni?: string;
}

export default class Curriculum extends React.Component<{}, IState>{
    constructor(props: {}) {
        super(props)
        this.state = {
            regularSession: undefined,
            educationSession: undefined,
            researchChallenge: undefined,
            meetingWithAlumni: undefined,
        }
    }



    private setCurriculum = () => {

        const regularSession = "<h3>Junior\n</h3>" +
            "<br/>" +
            "<p>신입 기수는 조별로 정한 기업에 대해 깊이 있는 분석 리포트를 3주를 주기로 하나씩 작성합니다. 1주 차는 종목 선정 및 산업, 기업 분석과 투자포인트, 2주 차는 매출 및 비용 추정 3주 차는 밸류에이션을 리포트에 담으면서 3주에 걸쳐 애널리스트 리포트를 완성합니다. 신입 기수의 경우, 리포트를 쓸 때 멘토들의 도움을 받고 매주 토요일 세션 때 발표와 질의응답을 통해 피드백을 받습니다. 운용 세션 동안 운용부장은 YIG 펀드 운용성과 발표 및 거시경제 이슈를 follow up 합니다. \n</p>" +
            "<br/>" +
            "<div style='text-align: center;'>" +
            `<img src=${RegularSession1} style='width: 100%; max-width: 700px'/>` +
            "</div>" +
            "<br/>" +
            "<h3>Acting\n</h3>" +
            "<br/>" +
            "<p>한 학기 활동을 마친 뒤, 신입학회원은 액팅 기수로 활동하게 됩니다. 액팅 기수로서 신입 기수들의 멘토 활동과 더불어 자신에게 맞는 팀에 속해 그에 따른 리포트를 작성하게 됩니다. 신입 학회원과는 다르게, 이미 금융에 대한 경험이 있는 만큼, 보다 심도 있는 분석을 경험합니다. 액팅 기수는 정규세션 때 깊이 있는 분석을 토대로 만든 리포트와 PPT를 주별로 돌아가며 발표합니다. \n</p>" +
            "<br/>"

        const educationSession = "<br/>" +
            "<p>YIG는 신입학회원을 대상으로 교육세션을 제공합니다. 교육세션 기간은 총 4주이며, 밸류에이션과 리서치를 주제로 진행됩니다. 세션은 체계적으로 계획된 커리큘럼을 바탕으로 진행됩니다. 1주차와 2주차에는 정규 세션에 도움이 될 수 있는 리서치와 밸류에이션 방법론에 대해서 학습합니다. 3주차와 4주차에는 배운 내용을 실제로 적용하고 이에 대해 피드백 하는 과정을 거칩니다. 이러한 과정을 통해 회계나 재무에 대한 이론적 바탕이 부족한 학회원들도 기업 분석에 대한 인사이트를 가질 수 있습니다.\n</p>" +
            "<br/>" +
            "<div style='text-align: center;'>" +
            `<img src=${EducationSession} style='width: 100%; max-width: 700px'/>` +
            "</div>" +
            "<br/>"

        const researchChallenge = "<br/>" +
            "<p>YIG의 활동은 금융 지식의 단순한 반복 학습에만 국한되지 않습니다. 경쟁적인 환경과 투자 지식의 적극적인 함양을 위하여 액팅 및 신입학회원을 대상으로 교육세션을 제공합니다. YIG 학회원들은 기본적으로 3개의 대회에 출전할 기회가 주어집니다. \n</p>" +
            "<br/>" +
            "<p>먼저, KEYSS대회입니다. KEYSS대회는 총 5개의 대학교(고려대, 서강대, 성균관대, 이화여대) 간의 대학 연합리서치 대회로 매 학기 정기적으로 진행하고 있습니다. 각 대학에서 선정한 종목에 대해 리포트를 작성하고 현직 애널리스트 및 펀드매니저 심사위원께 심사를 받는 식으로 진행됩니다. \n</p>" +
            "<br/>" +
            "<p>다음은, CFA 협회 주최 Global Investment Research Challenge에 참가합니다. 대한민국을 넘어 전 세계의 대학생과 경쟁할 수 있는 리서치 대회입니다. \n</p>" +
            "<br/>" +
            "<p>마지막으로 신입생 대회입니다. 신입 기수의 첫 학기가 끝날 때, YIG 내부적으로 신입생 리서치대회를 진행합니다. 현직에서 종사하시는 YIG Alumni 선배들께서 심사를 맡아 주십니다.\n</p>" +
            "<br/>" +
            "<p>이외에도 DB GAPS 대회, HYK 리서치 대회 등 국내외 유수의 대회에 학회 단위로 함께 도전합니다.\n</p>" +
            "<br/>" +
            "<div style='text-align: center;'>" +
            `<img src=${Curriculum4} style='width: 100%; max-width: 700px'/>` +
            "</div>" +
            "<br/>"

        const meetingWithAlumni = "<br/>" +
            "<p>YIG는 학회원을 대상으로 선배와의 만남을 진행합니다. 학기 중에는 금융업에 종사하시는 현직 선배님과 시간 조율을 통해 비정기적으로 ‘선배와의 만남＇ 세션을 갖습니다. 각종 실무 얘기와 해당 직종의 커리어 패스, 취업하기까지의 준비 과정 등 인턴 및 취업에 대한 궁금증을 해소할 수 있습니다. 졸업한 뒤에도 YIG를 찾아 주시는 정이 많은 선배와 좋은 네트워크를 형성할 기회입니다.\n</p>" +
            "<br/>" +
            "<p>이 외에도 국내외 증권사와의 세미나, 금융 및 투자 관련 방송 출연, 그리고 타 대학교 투자 학회와의 연계 세션 등 기타 활동 역시 활발히 경험하게 됩니다.\n</p>"


        this.setState({ regularSession, educationSession, researchChallenge, meetingWithAlumni: meetingWithAlumni })
    }

    public componentDidMount(): void {
        this.setCurriculum()
    }

    public render() {
        if (this.state.regularSession == null || this.state.educationSession == null || this.state.researchChallenge == null || this.state.meetingWithAlumni == null) {
            return null
        }

        return (
            <div style={{ marginLeft: "1em", marginRight: "1em" }}>
                <div className= "section">
                    <SectionHeader title={"정규 세션"}/>
                    <div style={{
                        marginLeft: "1em",
                        marginRight: "1em",
                        fontSize: "12pt"
                    }}
                        dangerouslySetInnerHTML={{ __html: this.state.regularSession }} />
                    <SectionHeaderWithSpace title={"교육 세션"}/>
                    <div style={{
                        marginLeft: "1em",
                        marginRight: "1em",
                        fontSize: "12pt"
                    }}
                        dangerouslySetInnerHTML={{ __html: this.state.educationSession }} />
                    <SectionHeaderWithSpace title={"리서치 대회"}/>
                    <div style={{
                        marginLeft: "1em",
                        marginRight: "1em",
                        fontSize: "12pt"
                    }}
                        dangerouslySetInnerHTML={{ __html: this.state.researchChallenge }} />
                    <SectionHeaderWithSpace title={"선배와의 만남 및 기타 활동"}/>
                    <div style={{
                        marginLeft: "1em",
                        marginRight: "1em",
                        fontSize: "12pt"
                    }}
                        dangerouslySetInnerHTML={{ __html: this.state.meetingWithAlumni }} />

                </div>
            </div>
        )
    }
}