import * as React from 'react';
import {Button, Icon} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router";


const ToRecruitButton = (props: RouteComponentProps<{}>) => (
    <Button icon labelPosition='right' basic size={"huge"} inverted={true} onClick={() => {
        props.history.push('/recruiting')
    }}>
        지원하기
        <Icon name='arrow right'/>
    </Button>
);


export default withRouter(ToRecruitButton)
