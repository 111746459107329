import React, { CSSProperties } from "react";
import { Accordion, AccordionTitleProps, Icon } from "semantic-ui-react";
import {SectionHeader} from "../common/SectionHeader";

interface IQuestionAndAnswer {
    question: string;
    answer: string;
}

interface IState {
    questionAndAnswer: IQuestionAndAnswer[];
    activeIndex: string | number | undefined;
}


export default class FAQ extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            questionAndAnswer: [],
            activeIndex: undefined
        }
    };

    private handleClick = (e: React.MouseEvent<HTMLDivElement>, titleProps: AccordionTitleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    private setFAQ = () => {
        const questionAndAnswer = [
            {
                question: "YIG 펀드 운용에 참여할 수 있나요?\n",
                answer: "신입 학회원이 직접 운용하는 것은 불가능하지만, 학회 세션 동안 발표한 종목이 YIG 펀드에 반영될 수 있습니다. \n"
            },
            {
                question: "상경계열이 아니라도 YIG에 지원할 수 있나요?",
                answer: "학과를 불문하고 주식에 관심이 있다면 누구나 YIG에 지원할 수 있습니다. 기업분석과 벨류에이션을 해야하기 떄문에 최소한의 기초 회계와 재무 지식을 가지고 있으면 도움이 될 것 입니다. 교육세션을 열심히 참여하시고, 정규 세션을 열심히 참여하시다보면 자연스럽게 관련 지식이 늘어날 수밖에 없습니다. 구 학회원들 중에서도, 인문계열, 이공계열 학회원들이 꽤 있었습니다. 학과는 문제되지 않습니다."
            },
            {
                question: "YIG 회원이 되기 위해서 무엇을 준비해야 하나요?",
                answer: "YIG는 서류와 면접 전형을 통해서 지원자분들의 주식에 대한 관심과 열정을 보고자 합니다. 지원서의 4번 문항(본인의 투자관, 투자하고 싶은 기업)을 깊이 있게 적어주시면 좋습니다."
            },
            {
                question: "꼭 3학기 연속으로 활동을 해야 하나요?",
                answer: "아닙니다. 교환학생이나 인턴 혹은 그밖의 개인적인 사정으로 잠시 이수학기를 중단할 수 있습니다. 하지만 반드시 정규 2학기 방학 1학기를 모두 활동해야 정규 학회원으로 인정됩니다."
            }
        ]

        this.setState({ questionAndAnswer })
    }

    public componentDidMount(): void {
        this.setFAQ()
    }

    private renderFAQ() {
        const sectionCSS: CSSProperties = {
            marginLeft: "1em",
            marginRight: "1em",
        }

        return (
            <div className="section" style={sectionCSS}>
                <Accordion fluid styled>
                    {this.state.questionAndAnswer.map((contents, i) => {
                        return (
                            this.renderAccordion(i, contents.question, contents.answer)
                        )
                    })}
                </Accordion>
            </div>
        )
    }

    public renderAccordion = (index: string | number | undefined, question: string, answer: string) => {
        const activeIndex = this.state.activeIndex
        return (
            <>
                <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={this.handleClick}>
                    <Icon name='dropdown' />
                    {question}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                    <p>{answer}</p>
                </Accordion.Content>
            </>
        )
    };

    public render() {
        return (
            <div className="section">
                <SectionHeader title={"FAQ"}/>
                {this.renderFAQ()}
            </div>
        )
    }
}
