import * as React from 'react';
import Logo from '../../asset/image/logo.jpg';
import api from '../../api';
import { ReportsCards } from "../yig/Reports";
import {Grid} from "semantic-ui-react";
import {SectionHeader, SectionHeaderWithSpace} from "../common/SectionHeader";

interface IState {
    reportsData: Array<any>;
    htmls?: string;
}


export default class YIGFund extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            reportsData: [],
            htmls: undefined
        }
    };


    public componentDidMount(): void {
        this.setMessages()
        this.setFundReports()
    };

    private setMessages = () => {

        const fundMessage =
            '<p>이번 YIG Fund의 운용을 맡게 된 37기 운용부장 박재상입니다. 저를 비롯한 운용부원들은 가치투자만이 올바른 투자방법이라고 믿고, 다음 5가지 원칙을 가지고 YIG Fund를 운용할 예정입니다. \n</p>' +
            '<p>1.   주식을 시세차익을 기대하고 투자하는 유가증권으로 바라보기보다는, 기업의 주인이 되는 소유권으로 바라보고 투자하고자 합니다. \n</p>' +
            '<p>2.   YIG 활동을 하면서 쌓은 기업 분석 역량을 최대한 활용할 수 있는 바텀업(Bottom-up) 투자를 지향하고자 합니다. 환율, 금리, 그리고 정부정책 등 거시적 지표들은 투자에 참고할 수는 있지만 예측하기가 불가능에 가깝습니다. 반면, 특정 기업에 대한 전망은 어느 정도 예측 범위 안에 있다고 생각합니다. \n</p>' +
            '<p>3.   소외되고 저평가된 중소형주 위주로 투자하고자 합니다. 주식 시장은 대체로 효율적입니다. 따라서 대형주 또는 주도주 등 시장의 주목을 상대적으로 많이 받는 종목들에 투자해서는 시장을 앞서가기 힘듭니다. 저희 펀드는 시장에서 철저하게 소외되어 가격과 가치 간의 괴리가 크게 벌어져 있는 종목에 투자하고자 합니다. \n</p>' +
            '<p>4.   가치함정(Value Trap)을 피하기 위해 촉매가 있는 기업 위주로 투자하고자 합니다. 저희 펀드의 운용 기간이 한 학기에 불과하다는 점을 고려하여, 단기간 내에 투자아이디어가 실현될 수 있는 촉매를 가진 종목들 위주로 투자하고자 합니다.  \n</p>' +
            '<p>5.   수익률 극대화를 위해 엄선한 5개 내외의 종목에 집중 투자하고자 합니다. \n</p>'

        this.setState({
            htmls: fundMessage
        })
    };

    private setFundReports = () => {
        // api.getFundReports()
        //     .then(res => {
        //         const reportsData = []
        //         for (let reportData of res.data) {
        //             reportData = {
        //                 companyName: reportData.subject,
        //                 imageSource: reportData.logo,
        //                 description: reportData.title,
        //                 publishedDate: reportData.published_date,
        //                 link: reportData.report,
        //             }
        //             reportsData.push(reportData)
        //         }

        //         this.setState({ reportsData });
        //     })
    }

    public render() {
        return (
            <div className="section">
                <SectionHeader title={"YIG Funds"}/>
                <div style={{ fontSize: "12pt", marginTop: "50px", marginBottom: "100px" }}>
                    {this.state.htmls && <div dangerouslySetInnerHTML={{ __html: this.state.htmls }}></div>}
                </div>
                <SectionHeaderWithSpace title={"Recent Performance"}/>
                <Grid stackable columns={4} style={{ marginTop: "2em" }}>
                    {this.state.reportsData.map((reports, i) => {
                        return (
                            <ReportsCards imageSource={reports.imageSource}
                                companyName={reports.companyName}
                                catchPhrase={reports.description}
                                publishedDate={reports.publishedDate}
                                link={reports.link}
                                key={i} />
                        )
                    })}
                </Grid>
            </div>
        )
    }
};
