import * as React from 'react';
import {primary} from "../atomic/Colors";


export const Banner = (props: {
    imageSrc: string,
    text: string
}) => {
    const {imageSrc, text} = props;
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100px',
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        }}>
            <div style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,35,102, 0.9)',
                color: primary.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20pt'
            }}>
                {text}
            </div>
        </div>
    )
};


export const InnerBanner = (props: {
    imageSrc: string,
    text: string
}) => {
    const {imageSrc, text} = props;
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100px',
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
        }}>
            <div style={{
                width: '100%',
                height: '100%',
                color: primary.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px'
            }}>
                {text}
            </div>
        </div>
    )
};
