import * as React from 'react';
import {createRef, RefObject} from 'react';
import Banner2 from '../../asset/image/main/main2.png';
import IntersectionObserverManager from "../../IntersectionObserverManager";
import './WhoWeAre.css';
import {Grid} from "semantic-ui-react";

interface IState {
    isShowingText: boolean
}

export default class WhoWeAre extends React.Component<{}, IState> {
    private intersectionObserver?: IntersectionObserverManager;
    private leftTextBox: RefObject<HTMLDivElement>;
    private rightTextBox: RefObject<HTMLDivElement>;

    constructor(props: {}) {
        super(props);
        this.leftTextBox = createRef();
        this.rightTextBox = createRef();
        this.state = {
            isShowingText: false
        }
    }

    public componentDidMount(): void {
        this.setIntersectionObserverAndSetAnimation()
    }

    public render() {
        return (
            <>
                <div style={{display:'flex', justifyContent:'flex-end', width:'100%', height:'calc(100%)'}}>
                    <div style={{
                        position:'relative',
                        backgroundImage: `url(${Banner2})`,
                        backgroundSize: 'cover',
                        width:'100%',
                        minWidth: '300px',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: "fixed",
                        backgroundPosition: 'center center',
                        zIndex: 0,
                        padding:'5em',
                        boxSizing:'border-box'
                    }}>
                        {this.renderLeftTextBox()}
                        {this.renderRightTextBox()}
                    </div>
                </div>
            </>
            )
            ;
    }
    
    private renderLeftTextBox = () => {
        return (
            <div ref={this.leftTextBox} className={'slide-up leftTextBox'}>
                <h1 className="ui header" style={{color: '#FFFFFF', fontSize: '3rem'}}>Who We Are</h1>

                <div style={{color: '#FFFFFF'}}>
                    Established in 2003, YIG is the only group on
                    campus to make strategic equity investments.

                    We build our portfolio based on research, financial
                    analysis and valuation of target companies.
                </div>
            </div>
        )
    };
    
    private renderRightTextBox = () => {
        return (
            <div ref={this.rightTextBox} className={'slide-up rightTextBox'}>
                <h1 className="ui header" style={{color: '#FFFFFF', fontSize: '3rem'}}>Our Vision</h1>

                <div style={{color: '#FFFFFF'}}>
                    We seek to evaluate target companies by their
                    intrinsic value and develop market insight to
                    harness real-world investment opportunities.
                </div>
            </div>
        )
    };

    private setIntersectionObserverAndSetAnimation = () => {
        const observer = new IntersectionObserverManager(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('slide-up');
                } else {
                    entry.target.classList.remove('slide-up');
                }
            })
        });

        const {leftTextBox, rightTextBox} = this;

        this.intersectionObserver = observer;

        this.intersectionObserver.observe(leftTextBox.current!);
        this.intersectionObserver.observe(rightTextBox.current!)
    };
}


