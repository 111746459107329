import * as React from 'react';
import '../../Common.css';
import {Grid} from "semantic-ui-react";
import VisionImg from "../../asset/image/yig/Vision.png"
import {SectionHeader, SectionHeaderWithSpace} from "../common/SectionHeader";
interface IState {
    history?: string;
    whoWeAre?: string;
    ourVision?: string;
}

export default class WhoWeAre extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            history: undefined,
            whoWeAre: undefined,
            ourVision: undefined
        }
    }

    public componentDidMount(): void {
        this.setHtmls()
    }

    private setHtmls = () => {

        const whoWeAre = "YIG는 2003년 경영, 산업공학, 건축학과 학생 6명이 모여 설립된 가치 투자 학회입니다. 소속 학과도 다르고 인원도 적었지만, 투자에 대한 관심과 열정을 가지고 의기투합한 투자 동아리에 그 모태를 두고 있습니다. 2004년 첫 YIG 펀드를 출범하여 가치 투자 및 운용 학회로서 본격적인 시작을 알리게 되었습니다.\n" +
            "우리 학회는 하루가 다르게 격변하는 시장 속에서 가치투자 철학을 바탕으로 여러 투자방법을 함께 공부하고 있습니다. 학회의 구성은 운용팀, 해외투자팀, 퀀트팀으로 나뉘며, 매주 정기 세션을 통해 각 팀에서 분석 보고서를 발표합니다. 이는 다시 Q&A와 피드백을 거쳐 각 팀의 최종 결정에 따라 각 팀 펀드에 편입됩니다.\n" +
            "YIG의 가장 큰 강점은 리서치와 운용에 대한 자유로운 피드백과 토론입니다. 회원들의 자유로운 의견 교환을 통해 여러 전공을 넘나들며 넓고 깊은 투자관의 형성을 돕고 새로운 투자 전문가로서의 자질을 키워나가는 것이 YIG의 모토입니다. 이러한 가치관을 필두로, YIG는 과거 CFA 주관의 Global Investment Research Challenge, DB GAPS 투자대회, KEYSS 연합 리서치 대회 등의 국내외 권위있는 대회에 도전하여 괄목할만한 성과를 거두었습니다.\n" +
            "또한 우리 학회는 2010년부터 고려대학교 RISK, 이화여자대학교 EIA, 서강대학교 SRS, 성균관대학교 STAR와 함께 KEYSS라는 각 대학교의 대표 투자 동아리들의 연합에 소속되어 교류를 증진하고, 상호간의 경쟁을 통해 시너지 효과를 창출하고자 노력하고 있습니다. 이와 더불어 2020년 하반기부터는 연세대학교 Data Science Lab과 협업하여 21세기 가장 앞서가는 Data Finance에 대한 경험 확충을 기획 중에 있습니다.\n"

        const outVision = "<p> YIG는 가치투자의 개념과 실제를 탐구하고 실전 적용을 통해 건전한 투자 방법을 체득하는 것을 목적으로 합니다.<br/></p>" +
            "<p> 미래 한국 자본시장을 이끌 금융 전문인을 꿈꾸는 학생들의 모임으로서, 불안정한 시장 상황일수록 시장 가격과 내재 가치를 구별하여 투자할 줄 아는 사고방식과 순수한 열정을 가진 현명한 투자자가 되는 것을 지향합니다.<br/></p>" +
            "<div style='text-align: center;'>" +
                `<img src=${VisionImg} style='width:100%; max-width: 500px;'/>` +
            "</div>"



        const dataFromServer =
                    '<p>' +
                        '<p><li>  2003년 설립\n</li></p>' +
                        '<p><li>  2004년 ㈜ FNGuide 프로젝트 수주\n</li></p>' +
                        '<p><li>  2004년 YIG 펀드 정식 출범\n</li></p>' +
                        '<p><li>  2006년 투자동아리 연합세션 주최\n</li></p>' +
                        '<p><li>  2008년 동양종합금융중권 1,4차 과제 우승\n</li></p>' +
                        '<p><li>  2009년 제1회 대학생 리서치대회 우승\n</li></p>' +
                        '<p><li>  2010년 YES 연합 리서치 대회 우승\n</li></p>' +
                        '<p><li>  2010년 CFA협회 주최 Global Investment Research Challenge 한국 대회 우승\n</li></p>' +
                        '<p><li>  2011년 CFA협회주최 Global Investment Research Challenge 아시아 대회 진출\n</li></p>' +
                        '<p><li>  2012년 SKYS 리서치대회 준우승 , KEYS 리서치 대회 우승\n</li></p>' +
                        '<p><li>  2013년 SKYS 리서치 대회 우승, KEYS 리서치 대회 준우승\n</li></p>' +
                        '<p><li>  2014년 SKYS 리서치대회 준우승\n</li></p>' +
                        '<p><li>  2014년 CFA International Research Competition 한국 대표 결선 진출\n</li></p>' +
                        '<p><li>  2015년 KEYSS 리서치 대회 우승\n</li></p>' +
                        '<p><li>  2017년 HYK 리서치 대회 우승, KEYSS 리서치 대회 준우승,\n</li></p>' +
                        '<p><li>  2018년 KEYSS 리서치 대회 준우승\n</li></p>' +
                        '<p><li>  2020년 KEYSS 리서치 대회 준우승\n</li></p>' +
                        '<p><li>  2020년 ‘슈카월드‘ 출연\n</li></p>' +
                        '<p><li>  2020년 연세대학교 Data Science Lab과 협업 체결, Data Finance팀 출범\n</li></p>' +
                        '<p><li>  2022년 KEYSS 대회 준우승\n</li></p>' +
                    '</p>';


        this.setState({
            history: dataFromServer,
            whoWeAre: whoWeAre,
            ourVision: outVision
        })
    };

    public render() {
        if (this.state.history == null || this.state.whoWeAre == null || this.state.ourVision == null) {
            return null
        }

        return (
            <div className="section">
                <SectionHeader title={"Who We Are"}/>
                    {this.state.whoWeAre.split("\n").map(line => (
                        <p>{line}<br/></p>
                    ))}
                <SectionHeaderWithSpace title={"Our Vision"}/>
                <div dangerouslySetInnerHTML={{ __html: this.state.ourVision }} />
                <SectionHeaderWithSpace title={"History"}/>
                <div style={{
                    marginLeft: "1em",
                    marginRight: "1em",
                    fontSize: "12pt"}}
                     dangerouslySetInnerHTML={{__html: this.state.history}}/>
            </div>
        )
    }
}

