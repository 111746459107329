import * as React from 'react';
import Logo from '../../asset/image/members/yiglogo.png';
import api from '../../api';
import {Card, Grid} from 'semantic-ui-react';
import {SectionHeader} from "../common/SectionHeader";

interface IReportData {
    companyName: string,
    imageSource: string,
    description: string,
    publishedDate: string,
    link: string,
}

interface IState {
    reportsData: IReportData [];
}

export default class Reports extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            reportsData: []
        };
    }

    private setHtmls = () => {
        // api.getReports()
        //     .then(res => {
        //         const reportsData = []
        //         for (let reportData of res.data) {
        //             reportData = {
        //                 companyName: reportData.subject,
        //                 imageSource: reportData.logo,
        //                 description: reportData.title,
        //                 publishedDate: reportData.published_date,
        //                 link: reportData.report,
        //             }
        //             reportsData.push(reportData)
        //         }

        //         this.setState({ reportsData });
        //     })
    }

    public componentDidMount(): void {
        this.setHtmls()
    };

    public render() {
        return (
            <div className="section">
                <SectionHeader title={"Reports List"}/>
                <Grid stackable columns={6} relaxed='very' style={{ marginTop: "2em" }}>
                    {this.state.reportsData.map((reports, i) => {
                        return (
                            <ReportsCards
                                imageSource={reports.imageSource}
                                companyName={reports.companyName}
                                catchPhrase={reports.description}
                                publishedDate={reports.publishedDate}
                                link={reports.link}
                                key={i} />
                        )
                    })}
                </Grid>
            </div>
        )
    };
}


export class ReportsCards extends React.Component
    <{
        companyName: string,
        catchPhrase: any,
        imageSource: any,
        publishedDate: any,
        link: any,
    }> {
    render() {
        let { companyName, catchPhrase, imageSource, publishedDate, link } = this.props;
        return (
            <Grid.Column style={{ paddingLeft: "1rem", paddingRight: "1rem"}}>
                <a href={link}>
                    <Card centered
                        image={imageSource}
                        header={companyName}
                        description={catchPhrase}
                        extra={"발행일 :".concat(publishedDate)}
                    />
                </a>
            </Grid.Column>
        )
    }
}

const {value, t}  = {value: 'test', t: 'erer'};