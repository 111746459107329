import * as React from 'react';
import WhoWeAre from "./WhoWeAre";
import Reports from "./Reports";
import Fund from "./YIGFund";
import {Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import Curriculum from "./Curriculum";
import '../../Common.css';
import {Banner, InnerBanner} from "../common/Banner";
import banner from '../../asset/image/main/main1.jpg';
import Banner1 from '../../asset/image/yig/Banner1.jpg'
import { Button } from 'semantic-ui-react';
interface IProps extends RouteComponentProps<{}> {

}

class YIG extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props)
    }

    public render() {
        return (
            <div className={'PageWrapper'}>
                {this.renderBanner()}
                {this.renderRouteButtons()}
                <div className={"PageInnerWrapper"}>
                    {this.renderSmallBanner()}
                    <Switch>
                        <Route exact={true} path={'/yig'} component={WhoWeAre}/>
                        <Route path={'/yig/reports'} component={Reports}/>
                        <Route path={'/yig/fund'} component={Fund}/>
                        <Route path={'/yig/curriculum'} component={Curriculum}/>
                    </Switch>
                </div>
            </div>

        )
    }

    private renderRouteButtons = () => {
        const buttonCSS: React.CSSProperties = {
            padding: "0 2em 0 2em",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            maxWidth: "600px"
        }
        return (
            <div style={buttonCSS}>
                <Button.Group widths='4'>
                    <Button onClick={() => {this.props.history.push('/yig')}}>Who We Are</Button>
                    {/* <Button onClick={() => {this.props.history.push('/yig/reports')}}>Reports</Button> */}
                    {/* <Button onClick={() => {this.props.history.push('/yig/fund')}}>Fund</Button> */}
                    <Button onClick={() => {this.props.history.push('/yig/curriculum')}}>Curriculum</Button>
                </Button.Group>
            </div>
        )
    };

    private renderBanner = () => {
       return <Banner text={'YIG'} imageSrc={banner} />
    };

    private renderSmallBanner = () => (<InnerBanner imageSrc={Banner1}  text={""}/>)
}


export default withRouter(YIG)