import React, {CSSProperties} from 'react';
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import Routes from "./Routes";
import {BrowserRouter} from "react-router-dom";


class Template extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }
    
    public render() {
        const headerAndPageStyle: CSSProperties = {width: '100%', minHeight: '100vh'};
        const headerStyle: CSSProperties = {
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        };
        
        const pageStyle: CSSProperties = {position: 'relative', width: '100%', zIndex: 0};

        return (
            <div>
                <BrowserRouter>
                    <div style={headerAndPageStyle}>
                        <div style={headerStyle}><Header/></div>
                        <div style={pageStyle}>
                            <Routes/>
                        </div>
                    </div>
                    <Footer/>
                </BrowserRouter>
            </div>
        );
    }
}

export default Template
