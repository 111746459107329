import Gnb from "./Gnb";
import {Link} from "react-router-dom";
import React, {useEffect, useRef} from 'react'
import {Grid, Image} from 'semantic-ui-react'
import './Header.css';
import Logo from '../../asset/image/header/text_logo.png';

import {
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react'

const MobileSideBar = () => {
    const [visible, setVisible] = React.useState(false);

    return (
        <Grid columns={1}>
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        inverted
                        onHide={() => setVisible(false)}
                        vertical
                        visible={visible}
                        width='thin'
                    >
                        <Menu.Item as='a'>
                            Home
                        </Menu.Item>
                        <Menu.Item as='a'>
                            Games
                        </Menu.Item>
                        <Menu.Item as='a'>
                            Channels
                        </Menu.Item>
                    </Sidebar>
                    <Sidebar.Pusher>
                        <div  onClick={()=>setVisible(true)}>
                            <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid>
    )
};


const MainHeader = () => (
    <Grid verticalAlign={'middle'} textAlign={'center'} padded={true}>
        <Grid.Row textAlign={'center'}>
            <Gnb/>
        </Grid.Row>
    </Grid>
);

const SubHeader = () => {
    return (
        <Grid>
            <Grid.Row>
                <Link to={'/yig'}>yig</Link>
            </Grid.Row>
        </Grid>
    )
};

const Header = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const logoRef = useRef<HTMLImageElement | null>(null);
    
    const isWindowScrollIsTop = () => {
       return window.scrollY === 0
    };
    
    const headerChangeEvent = () => {
      if(!isWindowScrollIsTop()){
          if(ref.current != null){
              addClassName(ref.current, 'toggled')
          }
          
          if(logoRef.current != null){
              addClassName(logoRef.current, 'toggled')

          }
      }  
      
      if(isWindowScrollIsTop()){
          if(ref.current != null){
              removeClassName(ref.current, 'toggled')
          }

          if(logoRef.current != null){
              removeClassName(logoRef.current, 'toggled')
          }
      }
    };
    
    const addClassName = (element: HTMLElement, className: string) => {
        element.classList.add(className)
    };
    
    const removeClassName = (element: HTMLElement, className: string) => {
        element.classList.remove(className)
    };
    
    useEffect(() => {
        window.addEventListener('scroll', headerChangeEvent);
        return () => {
            window.removeEventListener('scroll', headerChangeEvent);
        };
    },[]);

    return (
        <div ref={ref} className={'headerWrapper'} >
            <Grid className={'mainHeader'}>
                <Grid.Row width={12} centered={true}>
                    <div className={'logo'} ref={logoRef}><a href="/"><Image  src={Logo} size={"small"} /></a></div>
                    <Grid.Column>
                        <MainHeader/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
};




export default Header
