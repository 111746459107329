import * as React from 'react';
import Summary from "./Summary";
import AlumniMessage from './AlumniMessage';
import YigAlumni from "./YigAlumni";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import '../../Common.css';
import {Banner, InnerBanner} from "../common/Banner";
import banner from '../../asset/image/main/main1.jpg';
import {Button} from "semantic-ui-react";
import Banner2 from "../../asset/image/members/Banner2.jpg";


interface IProps extends RouteComponentProps<{}> {

}

export default class Members extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props)
    }

    public render() {
        return (
            <div className={'PageWrapper'}>
                {this.renderBanner()}
                {this.renderRouteButtons()}
                <div className={"PageInnerWrapper"}>
                    {this.renderSmallBanner()}
                    <Switch>
                        <Route exact={true} path={'/members'} component={Summary}/>
                        <Route path={'/members/AlumniMessage'} component={AlumniMessage}/>
                        {/* <Route path={'/members/YigAlumni'} component={YigAlumni}/> */}
                    </Switch>
                </div>
            </div>
        )
    }

    private renderRouteButtons = () => {
        const buttonCSS: React.CSSProperties = {
            padding: "0 2em 0 2em",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            maxWidth: "600px"
        }
        return (
            <div style={buttonCSS}>
                <Button.Group widths='3'>
                    <Button onClick={() => {this.props.history.push('/members')}}>Summary</Button>
                    <Button onClick={() => {this.props.history.push('/members/AlumniMessage')}}>Alumni Message</Button>
                    {/* <Button onClick={() => {this.props.history.push('/members/YigAlumni')}}>YIG Alumni</Button> */}
                </Button.Group>
            </div>
        )
    };

    private renderBanner = () => {
        return <Banner text={'Members'} imageSrc={banner} />
    };

    private renderSmallBanner = () => (<InnerBanner imageSrc={Banner2}  text={""}/>)
}


