import * as React from 'react';
import {Item} from "semantic-ui-react";
import Alumni1 from "../../asset/image/members/Alumni1.jpg";
import Alumni2 from "../../asset/image/members/Alumni2.jpg";
import {SectionHeader} from "../common/SectionHeader";

interface IAlumniMessage {
    imageSource: string;
    name: string;
    meta: string;
    message: string;
}

interface IState {
    alumniMessage: IAlumniMessage [];
}

export default class AlumniMessage extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            alumniMessage: []
        }
    }

    private setMessages = () => {
        const alumniMessage = [
            {
                imageSource: Alumni1,
                name: "정영훈",
                meta: "25기 신한BNP Paribas 자산운용",
                message: "현재 신한BNP Paribas 자산운용에서 Equity Research를 담당하고 있습니다. IT/SoftWare와 Media분야를 리서치하는 것이 현재 하고 있는 주 업무입니다. YIG 활동에서 지금까지 기억에 남는 것은 학회에서의 주식 운용 매니저로서의 경험과 리서치 대회에 공식 참가한 것입니다. 기업에 대한 In-Depth Report를 외부에 공표할 만큼 정교하게 써 보는 경험은 쉽게 할 수 없는 경험이며,학생 시기에 투자를 진지하게 생각해볼 수 있는 갚진 경험이라고 생각합니다. 본인의 의지에 따라 배우는 것이 많은 학회가 될 것이라고 생각합니다. \n"
            },
            {
                imageSource: Alumni2,
                name: "신현우",
                meta: "27기 삼성자산운용",
                message: "현재 삼성자산운용에서 인핸스드인덱스 및 액티브퀀트 펀드의 전략 리서치와 운용 업무를 맡고있습니다. YIG를 통해 얻은 것 중 가장 값진 것은 '기회' 였다고 생각합니다. 혼자 였다면 절대 얻지 못할 값진 기회들이 학회를 통해 저에게 찾아왔었습니다. 현업 선배들과의 네트워크, 애널리스트 리서치 세미나 및 대회 활동, 다양한 유형의 펀드 운용 등 모두 YIG가 아니었다면 얻을 수 없는 경험이라 생각합니다. 제가 그랬던 것처럼 앞으로도 더욱더 많은 분들이 YIG를 통해 소중한 경험과 기회를 얻어 가길 바랍니다."
            }
        ]

        this.setState({ alumniMessage })
    }

    public componentDidMount(): void {
        this.setMessages()
    };

    private renderMessage = (imageSource: string, name: string, meta: string, message: string) => {
        const itemCSS: React.CSSProperties = {
            marginTop: "1em"
        }
        return (
            <Item style={itemCSS}>
                <Item.Image src={ imageSource }/>
                <Item.Content verticalAlign="middle">
                    <Item.Header as='a'>{ name }</Item.Header>
                    <Item.Meta>
                        <span className='cinema'>{ meta }</span>
                    </Item.Meta>
                    <Item.Description>
                        <p>{ message }</p>
                    </Item.Description>
                </Item.Content>
            </Item>
        )
    }

    public render() {
        const itemCSS: React.CSSProperties = {
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto"
        }
        return (
            <div className="section">
                <SectionHeader title={"Alumni Message"}/>
                <Item.Group style={itemCSS}>
                    { this.state.alumniMessage.map(contents => {
                        return (
                            this.renderMessage(
                                contents.imageSource,
                                contents.name,
                                contents.meta,
                                contents.message)
                        )
                    })}
                </Item.Group>
            </div>
        )
    }
}